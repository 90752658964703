import { CurrentUser } from "../models/user.model";

export class BrowserUtils {

    static isUnload = false;

    static hasCookie(name) {
        return BrowserUtils.getCookie(name) !== undefined;
    }

    static getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    static escapeHtml(unsafe: string) {
        if (!unsafe) {
            return '';
        }
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;")
            .replace(/\//g, "&#x2F;");
    }

    static sanitizeHTML(text) {
        let element = document.createElement('div');
        element.innerText = text;
        return element.innerHTML;
    }

    static getXsrf() {
        let token = window.localStorage.getItem('xsrf');
        return token ? token : null;
    }

    static setXsrf(token) {
        window.localStorage.setItem('xsrf', token);
    }

    static downloadFile(blob: Blob, fileName: string) {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = fileName;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    static copyToClipboard(text: string) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then()
        } else {
            const textArea = document.createElement("textarea");
            textArea.style.position = 'fixed';
            textArea.style.top = String(0);
            textArea.style.left = String(0);
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select()
            try {
                document.execCommand('copy');
            } catch (e) {
                
            }
            document.body.removeChild(textArea);
        }
    }

    static getCurrentVersion(): string {
        return window['CURRENT_VERSION'];
    }

    static getCurrentYearMonth(): string {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        return `${year}-${month.toString().padStart(2, '0')}`;
    }

    static generateStorageKey(component: object, user: CurrentUser|null = null) {
        const name = component.constructor.name.replace('Component', '').replace('_', '');
        return `${this.camelCaseToDash(name)}${user ? ('_' + user.id) : ''}`;
    }

    static camelCaseToDash(myStr: string): string {
        return myStr.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }
}
